var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.algemeneBerichten)?_c('div',{staticClass:"nieuwsbericht"},[_c('transition-group',{attrs:{"name":"nieuwsbericht","appear":""}},[_vm._l((_vm.algemeneBerichten),function(bericht,i){return [(
          !bericht.isGelezen 
          && bericht.Bericht_actief === '1' 
          && new Date(bericht.DT_bericht_display_van) < Date.now() 
          && new Date(bericht.DT_bericht_display_tm) >= Date.now())?_c('div',{key:bericht.Nr,class:[
                      'nieuwsbericht__bericht',
                      { nieuwsbericht__activiteit: bericht.Type_bericht === '1' },
                      { nieuwsbericht__urgent: bericht.Type_bericht === '2' }
                  ]},[_c('div',{staticClass:"nieuwsbericht__content-container"},[_c('h2',{staticClass:"nieuwsbericht__header"},[_vm._v(_vm._s(bericht.Onderwerp))]),_c('div',{staticClass:"nieuwsbericht__content",domProps:{"innerHTML":_vm._s(bericht.Bericht)}})]),_c('div',{staticClass:"nieuwsbericht__sluiten",on:{"click":function($event){return _vm.berichtGelezen(i)}}},[_vm._v("[ OK ]")])]):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }